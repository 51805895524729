<!--<template>
    <v-row class="text-center">
      <v-col cols="12">
        <div class="my-10" />
        <h1>TEAMVIS</h1>
        <p>
          ミーティングを解析します。指定のファイルをアップロードして、”解析を開始"をクリックしてください
        </p>
        <div class="my-10" />
        <SimpleUploader />
        <div class="my-10" />
        <v-btn color="primary" x-large dark @click="login">
          <v-icon>解析を開始</v-icon>
        </v-btn>
      </v-col>
    </v-row>
</template>-->
<template>
  <section class="home-hero">
    <v-container fluid fill-height class="home-hero__content">
      <v-row>
        <v-col class="home-hero__content-text">
          <p>会議を解析し、チームワークを可視化する</p>
          <input
            style="display: none"
            ref="input"
            type="file"
            :accept="file.mimeTypes"
            multiple
            @change="selectedFile()"
          />
          <v-btn class="ma-2" outlined color="white" @click="btnclick">
            音声をアップロード
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <div class="my-12" />
        <h2>録音データを用いて会議を解析、チームワークを可視化。</h2>
        <div class="my-10" />
        <p>
          Teamvis.
          Liteでは、録音データをアップロードするだけで、議論の均等さや活発さなどを解析。
        </p>
        <p>
          チームワークの様子を可視化できます。
        </p>
        <div class="py-10" />
        <div class="gray py-10" v-if="screenStatus === 0">
          <div class="my-5" />
          <h2 class="my-30 pb-10">ミーティングを解析する</h2>
          <!--<div class="text-field">
            <div class="text-input">
              <input
                type="text"
                id="meeting"
                placeholder="ミーティング名を入力  (ex: 221026_teamB)"
                v-model="meeting"
              />
              <label for="input1">Title</label>
            </div>
          </div>
          <div class="text-field">
            <div class="text-input">
              <input
                type="text"
                id="members"
                placeholder="人数を数字で入力  (2以上6以下)"
                v-model="members"
              />
              <label for="input1">Count</label>
            </div>
          </div>
          <div class="text-field">
            <div class="text-input">
              <input
                type="text"
                id="start"
                placeholder="開始時刻を入力  (ex: 11:20)"
                v-model="start"
              />
              <label for="input1">Start</label>
            </div>
          </div>-->

          <div class="form">
            <v-row>
              <v-text-field
                v-model="meeting"
                :counter="20"
                label="ミーティング名を入力  (ex: 221026_teamB)"
                required
              ></v-text-field>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span>ファイル名や作成されるグラフに使用されます。</span>
              </v-tooltip>
            </v-row>
            <v-row>
              <v-select
                v-model="members"
                :items="items"
                label="人数を数字で入力  (2以上6以下)"
                required
              ></v-select>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span
                  >話者分離の際に使用されます。録音に参加している人数を入れてください。</span
                >
              </v-tooltip>
            </v-row>
            <v-row>
              <v-text-field
                v-model="start"
                label="録音開始時刻を入力  (ex: 11:20)"
                required
              ></v-text-field>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span
                  >作成されるグラフ等に使用されます。録音を開始した時刻を入力してください(例：11時20分⇨11:20)。</span
                >
              </v-tooltip>
            </v-row>
            <!--<v-dialog
              ref="dialog"
              v-model="modal2"
              :return-value.sync="start"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="start"
                  label="動画開始時刻を入力  (ex: 11:20)"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker v-if="modal2" v-model="start" full-width>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal2 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(start)">
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>-->
          </div>
          <div
            class="drop_area my-10"
            v-if="!isUpload"
            @click="btnclick"
            @dragover.prevent
            @drop.prevent="dropFile"
          >
            音声ファイルアップロード
          </div>
          <div class="my-5" />
          <div v-if="isUpload">Uploaded: {{ file.content[0].name }}</div>
          <v-btn color="#e03616" x-large dark @click="send_json" class="my-5">
            <v-icon>解析を開始</v-icon>
          </v-btn>
          <!--<v-btn color="#e03616" x-large dark @click="watch_s3" class="my-5">
            <v-icon>解析を開始</v-icon>
          </v-btn>-->
        </div>
        <div class="gray py-10" v-if="screenStatus === 1">
          <div class="loading">
            <vue-loading
              type="spin"
              color="#333"
              :size="{ width: '100px', height: '100px' }"
            ></vue-loading>
            <h2>Loading: {{ stage_percentage }}％</h2>
            <h2>Remaining Time: {{ remaining_time }}min</h2>
          </div>
        </div>
        <div class="gray py-10" v-if="screenStatus === 2">
          <h2 class="my-30 pb-10">解析が完了しました</h2>
          <img :src="diagram_url" width="50%" />
          <div class="my-10" />
          <p class="my-30 pb-10">
            {{ result_text }}
            <br />
          </p>
          <v-btn
            color="#e03616"
            x-large
            dark
            @click="download_csv"
            class="my-5"
          >
            <v-icon>ダウンロード</v-icon>
          </v-btn>
        </div>
        <div class="py-10">
          <v-container>
            <div class="my-10" />
            <v-row>
              <v-col md="6">
                <v-img
                  src="../assets/201014_WS8B_1_Diagram.png"
                  class="ml-auto"
                />
              </v-col>
              <v-col md="5" offset-md="1">
                <h2 class="font-weight-bold text-left">
                  様々な会議の様子を可視化、
                  <br />チームワークの分析・改善に活用可能
                </h2>
                <div class="my-10" />
                <p class="font-weight-regular text-left">
                  オンライン・対面の会議・ワークショップ録音データを解析し、対話の偏りやパターン・議論の活発度といったチームの議論の様子を可視化します。議論の様子を可視化することでチームワークの改善・分析に活用できます。
                  <br />
                  <br />
                  活用例
                  <br />
                  ・会議・ワークショップの参加者に可視化したものを提示し、フィードバックを行う
                  <br />
                  ・会議やワークショップで活発な議論が行われた時間を特定する
                </p>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="my-10" />
        <div class="gray py-10">
          <div class="my-10" />
          <v-container>
            <v-row>
              <v-col md="5">
                <div class="my-10" />
                <h2 class="font-weight-bold text-left">
                  i.schoolのワークショップ研究より生まれた分析手法
                </h2>
                <div class="my-10" />
                <p class="font-weight-regular text-left">
                  東大発イノベーション教育プログラムi.schoolでは、実施したワークショップにおける議論の可視化を以前より行っており、間の長さや対話の変動係数・対話量といった指標がチームワーク指標と関連があることが明らかになりつつあります。
                  <br />
                  <br />
                  ・対話の変動係数： 対話の偏りを示す指標です。
                  参加者の対話に偏りがないと0に近く，偏りが大きいと1に近い値をとります。
                  <br />
                  <br />
                  ・対話のパターン：
                  対話のパターンの変化を計算し、対話のパターン(区間)毎に対話の変動係数を計算しています。
                  <br />
                  <br />
                  ・線の厚み 対話のパターン(区間)ごとの対話量を示します。
                  <br />
                  <br />
                  ・線の濃さ 対話のパターン(区間)ごとの間の長さを示します。
                </p>
              </v-col>
              <v-col md="6" offset-md="1">
                <div class="my-10" />
                <v-img src="../assets/analysis.png" class="ml-auto" />
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="my-10" />
        <div class="py-10">
          <div class="my-10" />
          <v-container>
            <v-row>
              <v-col md="5">
                <div class="my-10" />
                <h2 class="my-10 font-weight-bold text-left" id="procedure">
                  分析の手順
                </h2>
                <div class="text-left">
                  <h3>1. 会議・ワークショップの音声を録音</h3>
                </div>
                <p class="font-weight-regular text-left">
                  ・オンライン環境では、全員の音声が入ったファイルを保存ください。
                  <br />
                  ・対面の会議・ワークショップの録音の際は、<a
                    href="https://drive.google.com/file/d/1U-Mvx2XilvMQ6H8MB_lTXXhPeH6kRZ69/view?usp=sharing"
                    >複数レコーダーで録音すること</a
                  >で発話者推定の精度を上げることができます。
                  <br />
                  ⇨下記「より良い解析のために」参照
                  <br />
                </p>
                <div class="text-left">
                  <h3>2. 録音データをアップロード＋必要な事項の入力</h3>
                </div>
                <p class="font-weight-regular text-left">
                  ・ファイルの上限の目安は300MBです。
                  <br />
                  ・解析時間は録音時間の1-3割程度かかります。
                  <br />
                  ・複数録音をアップロードする際は、音声ファイル名をレコーダー名の前後に”_”をつけたものとしてください。
                  <br />
                  （例：220502_レコーダー名_01.WAV)
                </p>
                <div class="text-left">
                  <h3>
                    3. Teamvis. Liteによる解析後、各種ファイルを出力
                  </h3>
                </div>
                <p class="font-weight-regular text-left">
                  ・本来いる人数に満たない解析結果が返ってきた等、解析精度が確保できない場合、
                  <a
                    href="https://drive.google.com/file/d/1U-Mvx2XilvMQ6H8MB_lTXXhPeH6kRZ69/view?usp=sharing"
                    >複数レコーダーでの録音</a
                  >を検討してください。
                </p>
              </v-col>
              <v-col md="6" offset-md="1">
                <div class="my-10" />
                <v-img src="../assets/procedure.png" class="ml-auto" />
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="py-10">
          <div class="my-10" />
          <v-container>
            <v-row>
              <v-col md="12">
                <h2 class="font-weight-bold text-left">
                  より良い解析のために
                </h2>
                <div class="text-left">
                  <h3>【共通】</h3>
                </div>
                <p class="font-weight-regular text-left">
                  ・なるべく良い音質での録音を行ってください。WAVか、FLAC等のロスレス形式でのアップロードをおすすめします。
                  <br />
                </p>
                <div class="text-left">
                  <h3>【対面】</h3>
                </div>
                <p class="font-weight-regular text-left">
                  ・雑音は解析結果に影響します。レコーダーの下にタオルを敷くなど、なるべく雑音が入らないようにしてください。
                  <br />
                  ・参加者それぞれの声がはっきりと判別できる位置にレコーダーを置いてください。
                </p>
                <div class="text-left">
                  <h3>【対面録音での解析精度が悪い場合】</h3>
                </div>
                <p class="font-weight-regular text-left">
                  ・本来いる人数に満たない解析結果が返ってきた等、解析精度が確保できない場合、<a
                    href="https://drive.google.com/file/d/1U-Mvx2XilvMQ6H8MB_lTXXhPeH6kRZ69/view?usp=sharing"
                    >複数レコーダーでの録音</a
                  >を検討してください。
                </p>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>
    <div class="dimgray py-10">
      <v-container>
        <v-row>
          <v-col>
            <a href="https://ischool.or.jp/">
              <v-img
                src="../assets/ischool_logo.svg"
                class="ml-auto mr-auto"
                max-width="80"
              />
            </a>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ this.msg }}
        </v-card-title>

        <v-card-text>
          {{ this.full_msg }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { API, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { VueLoading } from "vue-loading-template";
import axios from "axios";

export default {
  name: "Simple",
  components: { VueLoading },
  data() {
    return {
      isSingIn: false,
      file: {
        content: null,
        name: "audio_folder",
        icon: "mdi-folder-music-outline",
        mimeTypes: [
          "audio/aac",
          "audio/aiff",
          "audio/amr",
          "audio/ape",
          "audio/au",
          "video/avi",
          "audio/flac",
          "audio/gsm",
          "audio/x-ivf",
          "application/vnd.smaf",
          "video/quicktime",
          "video/mp4",
          "video/3gpp",
          "video/3gpp2",
          "video/mj2",
          "audio/mpeg",
          "application/vnd.mophun.certificate",
          "audio/ogg",
          "audio/oma",
          "audio/tak",
          "audio/tta",
          "audio/u16be",
          "audio/u16le",
          "audio/u24be",
          "audio/u24le",
          "audio/u32be",
          "audio/u32le",
          "audio/wav",
          "audio/x-m4a",
        ],
      },
      meeting: "",
      members: "",
      start: "",
      msg: "",
      full_msg: "",
      mtgId: "",
      diagram_url: "",
      isUpload: false,
      dialog: false,
      screenStatus: 0,
      timerObj: null,
      mtg_info: {},
      result_text: "",
      downloadfilelist: [],
      menu2: false,
      modal2: false,
      items: [2, 3, 4, 5, 6],
    };
  },
  computed: {
    stage: function() {
      if (this.mtg_info.stage || this.mtg_info.stage === 0) {
        return this.mtg_info.stage;
      }
      return 0;
    },
    stage_percentage: function() {
      return this.mtg_info.progress;
    },
    remaining_time: function() {
      return this.mtg_info.remaining_time;
    },
  },

  methods: {
    dropFile: async function() {
      console.log(event.dataTransfer.files);
      await this.upload_folder(event.dataTransfer.files);
      console.log("Enter Drop Area");
    },

    login() {
      console.log("login");
    },
    fileDrag() {
      console.log("enter");
    },
    btnclick() {
      console.log(this.$refs.input);
      this.$refs.input.click();
    },
    async selectedFile() {
      this.isUploading = true;

      const file = this.$refs.input.files;
      if (file) {
        this.file.content = file;
        this.upload_folder(file);
      }
    },
    upload_folder: function(file) {
      const content = file;
      // const uploadFolder = `mtgs/simple/AudioRecord/`;
      if (this.mtgId == "") {
        this.mtgId = uuidv4();
      }
      for (let j = 0; j < content.length; j++) {
        console.log(content[j].type);
        Storage.put(
          "mtgs/" + this.mtgId + "/AudioRecord/" + content[j].name,
          content[j],
          {
            level: "public",
            contentType: content[j].type,
            progressCallback: (progress) => {
              var msg = progress.loaded + " / " + progress.total;
              console.log(msg);
              console.log("koko");
              if (progress.loaded == progress.total) {
                msg = "upload complete!";
              } else if (
                parseInt(progress.loaded) &&
                parseInt(progress.total)
              ) {
                msg =
                  "uploading... " +
                  Math.floor(
                    (100.0 * parseInt(progress.loaded)) /
                      parseInt(progress.total)
                  ) +
                  " %";
              }
            },
          }
        )
          .then(
            (this.msg = "upload complete!"),
            (this.full_msg = "アップロードが完了しました"),
            (this.dialog = true),
            console.log(this.dialog),
            console.log(this.mtgId),
            console.log("here"),
            (this.isUpload = true),
            function(response) {
              // response 処理
              console.log(response);
            }
          )
          .catch(function(error) {
            // error 処理
            console.log(error);
          });
      }
    },
    check_data: function(jsonData) {
      // meeting名が存在しているか
      let error_message = "";
      let pattern = /^\d{2}:?\d{2}$/g;
      if (
        jsonData["start"].match(pattern) == null &&
        jsonData["start"].length > 0
      ) {
        error_message = "日付のフォーマットが間違っています（例：11:00)";
      }
      if (!Number.isInteger(jsonData["members"]) || jsonData["members"] == "") {
        error_message = "メンバー数に数値（半角）を入力してください";
      }
      if (jsonData["meeting"] == "") {
        error_message = "ミーティング名を記入してください";
      }
      return error_message;
    },
    send_json: async function() {
      let jsonData = {
        meeting: this.meeting,
        members: Number(this.members),
        stage: 0,
        start: this.start,
        progress: 1,
        remaining_time: 10,
      };
      let error_message = this.check_data(jsonData);
      if (error_message == "") {
        if (this.mtgId == "") {
          this.mtgId = uuidv4();
        }
        console.log(this.mtgId);
        Storage.put("mtgs/" + this.mtgId + "/mtg_info.json", jsonData, {
          level: "public",
          contentType: "application/json",
          progressCallback: (progress) => {
            var msg = progress.loaded + " / " + progress.total;
            console.log(msg);
            if (progress.loaded == progress.total) {
              msg = "upload complete!";
            } else if (parseInt(progress.loaded) && parseInt(progress.total)) {
              msg =
                "uploading... " +
                Math.floor(
                  (100.0 * parseInt(progress.loaded)) / parseInt(progress.total)
                ) +
                " %";
            }
          },
        })
          .then(
            (this.full_msg = "解析を開始します"),
            (this.dialog = true),
            (this.isUpload = true),
            console.log("here"),
            await this.batch(),
            function(response) {
              // response 処理
              console.log(response);
            }
          )
          .catch(function(error) {
            // error 処理
            console.log(error);
          });
      } else {
        console.log(error_message);
        this.msg = "エラー";
        this.full_msg = error_message;
        this.dialog = true;
      }
    },
    getUrl: async function(key, handler) {
      const dataExpireSeconds = 60 * 60; // 1時間有効なURLを取得
      Storage.get("mtgs/" + this.mtgId + "/" + key, {
        level: "public",
        expires: dataExpireSeconds,
      })
        .then((result) => {
          console.log(result);
          handler(result);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadMtgInfo: function(callback) {
      const dataExpireSeconds = 60;
      // const tmp_mtgId = "d1e14412-4012-428c-af84-cfa0ef53b8d0";
      Storage.get("mtgs/" + this.mtgId + "/mtg_info.json", {
        level: "public",
        expires: dataExpireSeconds,
      })
        .then((url) => {
          this.axios.get(url).then((result) => {
            console.log(result);
            this.mtg_info = result.data;
            callback();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    batch: async function() {
      const myInit = {
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        queryStringParameters: {
          mtgId: this.mtgId,
        },
      };
      const response = await API.get("lite1", "/lite1", myInit);
      this.batch_msg = response.message;
      console.log(this.batch_msg);
      this.screenStatus = true; //timer起動
      this.start_time();
    },

    // batch後定期的にs3を確認する処理(10秒ずつ)
    start_time: function() {
      this.timerObj = setInterval(this.watch_s3, 10000);
      this.screenStatus = 1; //timerがONであることを状態として保持
    },
    stop_time: function() {
      clearInterval(this.timerObj);
      this.screenStatus = 0; //timerがOFFであることを状態として保持
    },
    download_csv: function() {
      for (let i = 0; i < this.downloadfilelist.length; i++) {
        this.getUrl(this.downloadfilelist[i], (result) => {
          axios({
            url: result,
            method: "GET", // POSTでもok
            responseType: "blob", // これがないと文字化けする
          }).then((response) => {
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", this.downloadfilelist[i]); //ここらへんは適当に設定する
            document.body.appendChild(link);
            link.click();
            link.revokeObjectURL();
          });
        });
      }
    },

    watch_s3: function() {
      console.log("watch!");
      // mtg_infoの情報を取得
      this.loadMtgInfo(() => {
        if (this.stage == 8) {
          this.screenStatus = 2;
          // this.getUrl("calibration.csv", (result) => {
          //   this.calibration_url = result;
          // });
          this.result_text = this.mtg_info["text"];
          this.downloadfilelist = this.mtg_info["downloadfilelist"];
          this.getUrl(this.mtg_info["displayfile"], (result) => {
            this.diagram_url = result;
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-hero__content {
  position: relative;
  background-color: #000;
  width: 100%;
  height: 100vh;

  &-text {
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    z-index: 1;
  }
}

.home-hero__content::before {
  background: url("../assets/meeting.jpg");
  background-size: cover;
  background-position: center center;
  opacity: 0.5;
  z-index: 0;
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.gray {
  background-color: #f5f5f5;
}

.dimgray {
  background-color: #696969;
}

.drop_area {
  color: gray;
  font-weight: bold;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 300px;
  border: 5px solid gray;
  border-radius: 15px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.text-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
}

.text-input {
  position: relative;
  margin-top: 50px;

  input[type="text"] {
    display: inline-block;
    width: 500px;
    height: 40px;
    box-sizing: border-box;
    outline: none;
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 10px 10px 10px 100px;
    transition: all 0.1s ease-out;
  }

  input[type="text"] + label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    color: white;
    border-radius: 3px 0 0 3px;
    padding: 0 20px;
    background: #e03616;
    transform: translateZ(0) translateX(0);
    transition: all 0.3s ease-in;
    transition-delay: 0.2s;
  }

  input[type="text"]:focus + label {
    transform: translateY(-120%) translateX(0%);
    border-radius: 3px;
    transition: all 0.1s ease-out;
  }

  input[type="text"]:focus {
    padding: 10px;
    transition: all 0.3s ease-out;
    transition-delay: 0.2s;
  }
}

.v-text-field {
  width: 400px;
}
</style>
