<template>
  <v-app>
    <EnvMark />
    <v-main>
      <div v-if="CONST.IS_LITE">
        <SimpleHeader />
        <router-view name="simple" />
      </div>
      <div v-else>
        <Header :authState="authState" />
        <amplify-authenticator>
          <div v-if="authState === 'signedin'">
            <router-view />
          </div>
        </amplify-authenticator>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import EnvMark from "./components/EnvMark";
import Header from "./components/Header";
import SimpleHeader from "./components/SimpleHeader";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { CONST } from "./const";

export default {
  components: { EnvMark, Header, SimpleHeader },
  created() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      //if (authState === "signedin" && authData) {
      //  console.log("signed in.");
      //  console.log(authData);
      //}
    });
  },
  data() {
    return {
      CONST,
      user: undefined,
      authState: undefined,
    };
  },

  // beforeDestroy() {
  //   return onAuthUIStateChange;
  // },
};
</script>
