<template>
  <v-app-bar color="white" flat>
    <div class="d-flex align-center">
      <!--
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
        transition="scale-transition"
        width="40"
      />
      <v-img
        alt="Vuetify Name"
        class="shrink mt-1 hidden-sm-and-down"
        contain
        min-width="100"
        src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
        width="100"
      />
      -->
      <!--<v-btn text href="/" color="#333">
        teamvis
      </v-btn>-->
      <h1>Teamvis.</h1>
    </div>

    <v-spacer></v-spacer>
    <v-btn
      text
      href="https://drive.google.com/file/d/1WFOHr5DzUVbPLn1WJyWIEI4smgaS_qzp/view?usp=sharing"
      color="#333"
    >
      プライバシーポリシー
    </v-btn>
    <v-btn text href="https://ischool.or.jp/" color="#333">
      運営
    </v-btn>
    <v-btn text href="#procedure" color="#333">
      使い方
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {};
</script>
