import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Simple from "../views/Simple.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      default: Home,
      simple: Simple,
    },
  },
  {
    path: "/mtg/:id",
    name: "Mtg",
    component: () => import("../views/Mtg.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
